import { redirect, RouteObject } from 'react-router-dom'
import { AddressConfirmationPage } from './AddressConfirmationPage/AddressConfirmationPage.tsx'
import { AddressSchedulerFormPage } from './AddressSchedulerFormPage/AddressSchedulerFormPage.tsx'
import { DatePickerPage } from './DatePickerPage/DatePickerPage.tsx'
import { OverviewPage } from './OverviewPage/OverviewPage.tsx'
import { TimeZonePage } from './TimeZone/TimeZonePage'
import { ValidationResultsPage } from './ValidationResultsPage/ValidationResultsPage.tsx'

/**
 * Note: Top-level components need to render a Page Component
 */
export const addressSchedulerRoutes: RouteObject[] = [
  { index: true, loader: () => redirect('./overview') },
  { path: 'overview', element: <OverviewPage /> },
  { path: 'address-form', element: <AddressSchedulerFormPage /> },
  { path: 'address-suggestion', element: <AddressSchedulerFormPage /> },
  { path: 'address-confirmation', element: <AddressSchedulerFormPage /> },
  { path: 'validation-results', element: <ValidationResultsPage /> },
  { path: 'confirmation', element: <AddressConfirmationPage /> },
  { path: 'time-zone', element: <TimeZonePage /> },
  { path: 'date-picker', element: <DatePickerPage /> }
]
