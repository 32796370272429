import ErrorIcon from '@mui/icons-material/Error'
import { Alert, AlertTitle, Box } from '@mui/material'
import { FC, useMemo } from 'react'
import { Location } from '../../api'
import { getBannerErrorMessage } from '../../utils/permitUtils.ts'

type BannerErrorProps = {
  location: Location
}

export const BannerError: FC<BannerErrorProps> = ({ location }) => {
  const bannerErrorMessage = useMemo(() => getBannerErrorMessage(location), [location])

  if (!bannerErrorMessage) {
    return null
  }

  const { color, title, body } = bannerErrorMessage

  return (
    <Box data-testid="banner-error" sx={{ inlineSize: '100%', marginBlockEnd: 'var(--spacing-m)' }}>
      <Alert
        color={color}
        icon={<ErrorIcon />}
        variant="filled"
        sx={{
          padding: '0.375rem 1rem',
          borderRadius: '0.625rem'
        }}
      >
        <AlertTitle
          sx={{
            fontSize: '1.25rem',
            fontWeight: '500',
            textTransform: 'capitalize',
            lineHeight: 'normal'
          }}
        >
          {title}
        </AlertTitle>
        {body}
      </Alert>
    </Box>
  )
}
