import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { Page } from '../../../components/Page'
import { useForm } from 'react-hook-form'
import { LocationChangeTimeZone } from '../../../api'
import { VALID_TIME_ZONES } from '../../../constants'
import { useAtom } from 'jotai'
import { currentLocationChangeAtom } from '../../../atoms'
import { ErrorScreen } from '../../../components/ErrorScreen'
import { AddressConfirmationDialog } from '../AddressConfirmationPage/AddressConfirmationDialog'

type LocationChangeTimeZoneForm = {
  timeZone: LocationChangeTimeZone
}

export const TimeZonePage: FC = () => {
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
    setValue,
    trigger
  } = useForm<LocationChangeTimeZoneForm>()

  const [currentLocationAtom, setCurrentLocationAtom] = useAtom(currentLocationChangeAtom)
  const [isConfirmTimezoneDialogOpen, setIsConfirmTimezoneDialogOpen] = useState(false)

  const onSubmit = async () => {
    setIsConfirmTimezoneDialogOpen(true)
  }

  const handleConfirmTimezone = () => {
    if (currentLocationAtom) {
      // Add timezone to location change atom.
      const currentLocationAddressWithTimezone = {
        ...currentLocationAtom.address,
        timeZone: watch('timeZone')
      }
      const updatedCurrentLocationAtom = {
        address: currentLocationAddressWithTimezone,
        ...currentLocationAtom
      }
      // @ts-expect-error - it's warning that street and country could be undefined which they won't be.
      // TODO: Consult with team to determine if there is a better way to clarify this.
      setCurrentLocationAtom(updatedCurrentLocationAtom)

      // Navigate to confirmation
      navigate('../date-picker')
    }
  }

  if (!currentLocationAtom) {
    return (
      <Page title={'Address Change Scheduler'}>
        <ErrorScreen data-testid="error-screen" title="Oops, something went wrong!" message={'A refresh may fix this issue.'} flexGrow={1} />
      </Page>
    )
  }

  return (
    <Page title={'Address Change Scheduler'}>
      <Stack sx={{ position: 'relative' }}>
        <Stack spacing={'var(--spacing-l)'} sx={{ width: '100%' }}>
          <Box
            sx={{
              bgcolor: 'var(--bg-surface)',
              borderRadius: 'var(--radius-m)',
              p: 'var(--spacing-m)',
              gap: 'var(--spacing-xl)'
            }}
          >
            <Stack spacing={'var(--spacing-l)'}>
              <Typography variant="h2" fontSize={'var(--size-h1)'} fontWeight="500" color="text.primary">
                Schedule an Address Change
              </Typography>
              <Stack sx={{ marginTop: 'var(--spacing-xl)', marginLeft: 'var(--spacing-m)' }} spacing={'var(--spacing-m)'}>
                <Typography variant="h3" fontSize={'var(--size-h2)'} fontWeight="500" color="text.primary">
                  Verify Timezone
                </Typography>
                <Typography variant="body1" fontSize={'var(--size-l)'} fontWeight="400" color="text.primary">
                  We couldn’t verify the timezone for this address. Please review before continuing. This will affect the time shown to our monitoring
                  agents in an emergency.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={'var(--spacing-l)'}>
                    <TextField
                      {...register('timeZone', {
                        required: 'Timezone is required'
                      })}
                      slotProps={{
                        // @ts-expect-error - select doesn't expose the data attributes but this is valid
                        select: { ['data-testid']: 'timeZone' }
                      }}
                      select
                      value={watch('timeZone') || ''}
                      label="Time Zone"
                      error={!!errors.timeZone}
                      helperText={errors.timeZone?.message}
                      onChange={e => {
                        setValue('timeZone', e.target.value as LocationChangeTimeZone)
                        trigger()
                      }}
                    >
                      {VALID_TIME_ZONES.map(type => {
                        return (
                          <MenuItem key={type} data-testid="timeZone_value" value={type}>
                            {type}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                    <Divider sx={{ borderColor: 'var(--bg-surface-medium)' }} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 'var(--spacing-s)' }}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          navigate(-1)
                        }}
                        data-testid={'cancel-time-zone-selection-button'}
                      >
                        Cancel
                      </Button>
                      <Button disabled={!isValid} variant="contained" color="primary" type="submit" data-testid={'time-zone-confirm-button'}>
                        Continue
                      </Button>
                    </Box>
                  </Stack>
                </form>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <AddressConfirmationDialog
        isOpen={isConfirmTimezoneDialogOpen}
        onClose={() => {
          setIsConfirmTimezoneDialogOpen(false)
        }}
        onConfirm={handleConfirmTimezone}
        customerAddress={{
          street1: currentLocationAtom.address?.street1,
          city: currentLocationAtom.address?.city,
          zip: currentLocationAtom.address?.postalCode
        }}
      />
    </Page>
  )
}
