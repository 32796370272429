import { Box } from '@mui/material'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation as useLocationData } from '../../api/apiQueries.ts'
import { AuthCheck } from '../../components/AuthCheck'
import { READ_PERMITTING_COMMENTS_SCOPE } from '../../constants'
import { Changelog } from './Changelog.tsx'
import { Notes } from './Notes.tsx'
import { PermitsTable } from './PermitsTable.tsx'

/**
 * JurisdictionDetailsTable component.
 * Renders a table filled with jurisdiction-related data.
 */
export const PermitsOverview: FC = () => {
  const { sid: locationId } = useParams()
  const locationQuery = useLocationData(locationId)

  const locationData = locationQuery.data

  return (
    locationData && (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-l)' }}>
        <PermitsTable location={locationData} />
        <Changelog location={locationData} />
        <AuthCheck has={READ_PERMITTING_COMMENTS_SCOPE}>
          <Notes location={locationData} />
        </AuthCheck>
      </Box>
    )
  )
}
