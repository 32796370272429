import { TabProps } from '@mui/material/Tab/Tab'
import { Link, matchPath, type UIMatch, useLocation, useMatches } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'
import { TabsProps } from '@mui/material/Tabs/Tabs'

type NavTabProps = Omit<TabsProps, 'value'> & {
  dataTestId?: string
  tabs: Array<
    TabProps & {
      to: string
      dataTestId?: string
    }
  >
}

const getActiveTab = (matchingRoutes: UIMatch[], path: string, pathname: string) => {
  for (let i = 0; i < matchingRoutes.length; i += 1) {
    const matchingRoute = matchingRoutes[i]
    const possibleMatch = matchPath(matchingRoute.pathname, pathname)
    if (possibleMatch !== null) {
      return possibleMatch?.pattern?.path
    }
  }
  return null
}

export const NavTabs = (props: NavTabProps) => {
  const { tabs, dataTestId, ...tabsProps } = props
  const location = useLocation()
  const matchingRoutes = useMatches()

  let activeTab: string | null = ''
  for (let i = 0; i < tabs.length; i += 1) {
    const tab = tabs[i]
    activeTab = getActiveTab(matchingRoutes, tab.to, location.pathname)
    if (activeTab !== null) {
      break
    }
  }

  return (
    <Tabs value={activeTab} data-testid={dataTestId} {...tabsProps} sx={{ marginBottom: 'var(--spacing-s)' }}>
      {tabs.map(tab => {
        const { to, dataTestId, ...rest } = tab
        const tabProps = rest as TabProps
        // @ts-expect-error - TS isn't able to recognize that the `to` prop is part of the Link component
        return <Tab {...tabProps} to={to} value={to} component={Link} data-testid={dataTestId} />
      })}
    </Tabs>
  )
}
