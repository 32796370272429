import { createTheme } from '@mui/material/styles'

import { bpThemeOptions } from '@simplisafe/blueprint-web-dsl'

const overrides = {
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 'var(--spacing-s)',
          fontSize: 'var(--size-h2)',
          fontWeight: '500',
          color: 'var(--text-primary)',
          lineHeight: 'var(--lineheight-xl, 1.5rem)',
          letterSpacing: 'var(--tracking-default, 0rem)'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined'
      }
    }
  }
}

export const theme = createTheme(bpThemeOptions, overrides)
