export interface AuthenticationContextValues {
  accessToken: string | undefined
  hasAllScopes: (scopes: string[]) => boolean
  hasAnyScope: (scopes: string[]) => boolean
  has(...scopes: string[]): boolean
}

export class AuthenticationContextValuesImpl implements AuthenticationContextValues {
  accessToken: string | undefined
  private readonly scopes: Set<string>

  // This is used for testing purposes so that we can mock out the scopes we want to emulate on a test by test basis, hence why it is not part of the interface.
  testScopeOverrides: Set<string> | undefined

  constructor(accessToken: string | undefined, scopes: string[]) {
    this.accessToken = accessToken
    this.scopes = new Set(scopes)
  }

  setTestScopeOverrides(scopes: string[]): void {
    this.testScopeOverrides = new Set(scopes)
  }

  clearTestScopeOverrides(): void {
    this.testScopeOverrides = undefined
  }

  getScopes(): Set<string> {
    return this.testScopeOverrides || this.scopes
  }

  hasAllScopes(scopes: string[]): boolean {
    return scopes.every(scope => this.getScopes().has(scope))
  }

  hasAnyScope(scopes: string[]): boolean {
    return scopes.some(scope => this.getScopes().has(scope))
  }

  has(...scopes: string[]): boolean {
    return this.hasAllScopes(scopes)
  }
}
