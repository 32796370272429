import { useMediaQuery } from '@mui/material'
import { Mode } from '@mui/system/cssVars/useCurrentColorScheme'
import { useAtom } from 'jotai'
import { colorModePreferenceAtom } from '../atoms'

/**
 * The return type of the `useColorMode` hook.
 *
 * It provides mechanisms to access the Blueprint color mode preference set by the user, to establish a new preference,
 * and to determine the effective color mode based on the user's preference and system settings.
 */
export type UseColorModeResult = {
  /**
   * The Blueprint color mode preference set by the user, used to determine which color mode to use. It can be
   * `"light"`, `"dark"`, or `"system"`.
   */
  colorModePreference: Mode

  /**
   * Sets the Blueprint color mode preference for the user, used to determine which color mode to use.
   *
   * @param colorModePreference the Blueprint color mode to establish as the user's preference.
   */
  setColorModePreference: (colorModePreference: Mode) => void

  /**
   * The actual color mode used by the app for rendering. It can be either `"light"` or `"dark"`.
   *
   * If the current Blueprint color mode preference is set to `"system"`, the color mode is calculated based on the
   * user's system settings.
   */
  colorMode: Exclude<Mode, 'system'>
}

/**
 * A hook that provides mechanisms to access the Blueprint color mode preference set by the user, to establish a new
 * preference, and to determine the effective color mode based on the user's preference and system settings.
 */
export const useColorMode = (): UseColorModeResult => {
  const [colorModePreference, setColorModePreference] = useAtom(colorModePreferenceAtom)
  const userPrefersDarkColorScheme = useMediaQuery('(prefers-color-scheme:dark)')

  const systemColorMode = userPrefersDarkColorScheme ? 'dark' : 'light'

  const colorMode = colorModePreference === 'system' ? systemColorMode : colorModePreference

  return {
    colorModePreference,
    setColorModePreference,
    colorMode
  }
}
