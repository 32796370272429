import { Mode } from '@mui/system/cssVars/useCurrentColorScheme'
import { atom } from 'jotai'
import { AddressValidationResult, LocationChange } from '../api'
import { getColorModePreferenceFromStorage, setColorModePreferenceInStorage } from '../utils/colorModeUtils'

export const currentLocationChangeAtom = atom<Partial<LocationChange>>()
export const addressValidationResultsAtom = atom<AddressValidationResult>()
export const selectedAddressValidationResultAtom = atom<number | 'UNVERIFIED'>()
export const colorModeContainerAtom = atom<HTMLDivElement>()

/**
 * Atom that contains the current Blueprint color mode preference saved in local storage.
 */
const colorModePreferenceInStorageAtom = atom<Mode>(getColorModePreferenceFromStorage())

/**
 * Atom that contains the current Blueprint color mode preference.
 *
 * @remarks
 *
 * Any time this atom is updated, the new preference is also saved in local storage.
 */
export const colorModePreferenceAtom = atom(
  get => {
    return get(colorModePreferenceInStorageAtom)
  },
  (get, set, colorModePreference: Mode) => {
    setColorModePreferenceInStorage(colorModePreference)
    set(colorModePreferenceInStorageAtom, colorModePreference)
  }
)
