import { Close } from '@mui/icons-material'
import { IconButton, styled } from '@mui/material'
import { closeSnackbar, MaterialDesignContent, SnackbarKey, SnackbarProvider, SnackbarProviderProps } from 'notistack'
import { FC, PropsWithChildren } from 'react'

/**
 * Will only render an outlet if the user has the required scopes.
 * Otherwise, the user will see the UnauthorizedPage.
 */
export const StyledSnackbarProvider: FC<PropsWithChildren<SnackbarProviderProps>> = ({ children, ...rest }) => {
  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      color: 'var(--text-toast)',
      backgroundColor: 'var(--bg-toast)',
      '& .MuiSvgIcon-root': {
        color: 'var(--text-toast)'
      }
    },
    '&.notistack-MuiContent-error': {
      color: 'var(--text-toast)',
      backgroundColor: 'var(--bg-toast)',
      '& .MuiSvgIcon-root': {
        color: 'var(--text-toast)'
      }
    },
    '&.notistack-MuiContent-info': {
      color: 'var(--text-toast)',
      backgroundColor: 'var(--bg-toast)',
      '& .MuiSvgIcon-root': {
        color: 'var(--text-toast)'
      }
    },
    '&.notistack-MuiContent-warning': {
      color: 'var(--text-toast)',
      backgroundColor: 'var(--bg-toast)',
      '& .MuiSvgIcon-root': {
        color: 'var(--text-toast)'
      }
    }
  }))

  return (
    <SnackbarProvider
      maxSnack={3}
      action={(key: SnackbarKey) => (
        <IconButton onClick={() => closeSnackbar(key)} aria-label={'dismiss'} sx={{ color: 'white' }}>
          <Close />
        </IconButton>
      )}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent
      }}
      {...rest}
    >
      {children}
    </SnackbarProvider>
  )
}
