import { FC } from 'react'
import { Typography } from '@mui/material'

interface HeaderProps {
  headerText?: string
}

export const Header: FC<HeaderProps> = ({ headerText }) => {
  return (
    <Typography
      component="h1"
      sx={{
        fontSize: 'var(--size-display)',
        fontWeight: '700',
        fontStyle: 'normal',
        lineHeight: 'normal'
      }}
    >
      {headerText}
    </Typography>
  )
}
