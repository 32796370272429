import { createBrowserRouter, Outlet, redirect, RouteObject } from 'react-router-dom'
import { RouteGuard } from '../components/RouteGuard'
import { ADDRESS_SCHEDULER_APPLET_SCOPES, ALARM_REVIEW_APPLET_SCOPES, ALARM_SMS_REVIEW_APPLET_SCOPES, PERMITTING_APPLET_SCOPES } from '../constants'
import { AppLayout } from '../layouts/AppLayout'
import { addressSchedulerRoutes } from './address-scheduler/addressScheduler.routes.tsx'
import { AlarmSmsPage } from './AlarmSmsPage'
import { AlarmsPage } from './AlarmsPage'
import { FeatureViewerPage } from './FeatureViewerPage'
import { LandingPage } from './LandingPage'
import { NotFoundPage } from './NotFoundPage'
import { PermitPage } from './PermitPage'
import { JurisdictionDetailsTable } from './PermitPage/JurisdictionDetailsTable.tsx'
import { PermitsOverview } from './PermitPage/PermitsOverview.tsx'
import { UnauthorizedPage } from './UnauthorizedPage'

/**
 * Note: Top-level components need to render a Page Component
 */
export const routes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: '/location/:sid?',
        children: [
          {
            index: true,
            element: <LandingPage />
          },
          {
            element: <RouteGuard element={<Outlet />} has={ALARM_SMS_REVIEW_APPLET_SCOPES} mode={'all'} />,
            children: [
              {
                path: 'alarm-sms-review/',
                element: <AlarmsPage />
              },
              {
                // TODO: Does it make sense to allow this page to load without a valid locationId?
                path: 'alarm-sms-review/:alarmId?',
                element: <AlarmSmsPage />
              }
            ]
          },
          {
            element: <RouteGuard element={<Outlet />} has={ALARM_REVIEW_APPLET_SCOPES} mode={'all'} />,
            children: [
              {
                path: 'location-features',
                element: <FeatureViewerPage />
              }
            ]
          },
          {
            path: 'permits',
            element: <RouteGuard element={<PermitPage />} has={PERMITTING_APPLET_SCOPES} mode={'all'} />,
            children: [
              {
                index: true,
                loader: () => redirect('./overview')
              },
              {
                path: 'overview',
                element: <PermitsOverview />
              },
              {
                path: 'jurisdiction-details',
                element: <JurisdictionDetailsTable />
              }
            ]
          },
          {
            path: 'address-scheduler',
            element: <RouteGuard element={<Outlet />} has={ADDRESS_SCHEDULER_APPLET_SCOPES} mode={'all'} />,
            children: addressSchedulerRoutes
          }
        ]
      },
      {
        // Route added for backwards compatibility with the CRM.
        path: '/permits/:sid?',
        loader: ({ params }) => {
          if (params.sid) {
            return redirect(`/location/${params.sid}/permits`)
          } else {
            return redirect('/location/permits')
          }
        }
      },
      {
        // Route added for backwards compatibility with the CRM.
        path: '/address-scheduler*',
        loader: ({ params }) => {
          const restOfPath = params['*'] ?? ''

          // Regex that splits the path into two halves: a first segment and the rest of the path.
          const restOfPathRegex = /^(?<firstSegment>[^/?#]*)(?<rest>.*)?$/

          const restOfPathMatch = restOfPath.match(restOfPathRegex)!
          const { firstSegment, rest = '' } = restOfPathMatch.groups as { firstSegment: string; rest: string }

          if (firstSegment === '') {
            // An empty first segment means no locationId was provided, so navigate to the address scheduler overview.
            return redirect('/location/address-scheduler')
          } else {
            // Otherwise, redirect to the address scheduler with the locationId and the rest of the path.
            return redirect(`/location/${firstSegment}/address-scheduler${rest}`)
          }
        }
      },
      {
        path: 'unauthorized',
        element: <UnauthorizedPage />
      },
      {
        path: 'not-found',
        element: <NotFoundPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
]

export const appRouter = createBrowserRouter(routes)
