import { FC } from 'react'
import { AlarmTable } from './AlarmTable'
import { useParams } from 'react-router-dom'
import { useLocationAlarms } from '../../api/apiQueries'
import { Skeleton } from '../../components/Skeleton/Skeleton'

export const AlarmList: FC = () => {
  const { sid: locationId } = useParams()

  const alarmQuery = useLocationAlarms(locationId)
  const alarmData = alarmQuery.data

  return (
    <>
      {alarmData ? (
        <AlarmTable alarms={alarmData?.items ?? []} />
      ) : (
        alarmQuery.isLoading && <Skeleton isLoading={true} variant={'rounded'} sx={{ height: '40rem', width: '100%' }} />
      )}
    </>
  )
}
