import { Mode } from '@mui/system/cssVars/useCurrentColorScheme'

/**
 * The key used to save the Blueprint color mode preference in local storage.
 */
export const COLOR_MODE_PREFERENCE_STORAGE_KEY = 'ss-color-mode' as const

/**
 * The default Blueprint color mode preference, used when no preference is found in local storage.
 */
export const DEFAULT_COLOR_MODE_PREFERENCE = 'system' as const satisfies Mode

/**
 * Checks whether or not `value` is a valid Blueprint color mode preference.
 *
 * @param value the value to check.
 * @returns whether or not `value` is a valid Blueprint color mode preference.
 */
const isValidColorModePreference = (value: unknown): value is Mode => {
  const validColorModePreferences: string[] = ['light', 'dark', 'system'] satisfies Mode[]
  return typeof value === 'string' && validColorModePreferences.includes(value)
}

/**
 * Returns the Blueprint color mode preference saved in local storage.
 *
 * If no preference is found when requested, the default preference (`"system"`) is saved in local storage and then
 * returned.
 *
 * @returns The Blueprint color mode preference saved in local storage.
 */
export const getColorModePreferenceFromStorage = (): Mode => {
  const storedPreference = localStorage.getItem(COLOR_MODE_PREFERENCE_STORAGE_KEY)

  if (isValidColorModePreference(storedPreference)) {
    return storedPreference
  } else {
    setColorModePreferenceInStorage(DEFAULT_COLOR_MODE_PREFERENCE)
    return DEFAULT_COLOR_MODE_PREFERENCE
  }
}

/**
 * Saves a Blueprint color mode preference in local storage.
 *
 * @param colorModePreference the Blueprint color mode preference to save in local storage.
 */
export const setColorModePreferenceInStorage = (colorModePreference: Mode): void => {
  localStorage.setItem(COLOR_MODE_PREFERENCE_STORAGE_KEY, colorModePreference)
}
