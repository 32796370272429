import { FC } from 'react'
import { AlarmList } from './AlarmList'
import { Page } from '../../components/Page'

export const AlarmsPage: FC = () => {
  return (
    <Page title="Alarm SMS Review">
      <AlarmList />
    </Page>
  )
}
