import { Box, Link, Stack } from '@mui/material'
import { FC } from 'react'
import { Link as ReactRouterLink, useParams } from 'react-router-dom'
import { useLocationAlarm } from '../../api/apiQueries'
import { Page } from '../../components/Page'
import { Skeleton } from '../../components/Skeleton/Skeleton'
import { AlarmCard } from './AlarmCard'
import { AlarmSmsView } from './AlarmSmsView'

export const AlarmSmsPage: FC = () => {
  const { sid, alarmId } = useParams()

  const alarmQuery = useLocationAlarm(sid, alarmId)
  const alarm = alarmQuery.data

  return (
    <Page title="Alarm SMS Review">
      <Stack>
        <Box alignSelf={'start'} paddingBottom={'4rem'}>
          <Link component={ReactRouterLink} to=".." relative="path" color="primary" data-testid="back-to-all-alarms-button">
            Back to All Alarms for Location '{sid}'
          </Link>
        </Box>
        {alarm ? (
          <AlarmCard alarm={alarm} />
        ) : (
          alarmQuery.isLoading && <Skeleton isLoading={true} variant={'rounded'} sx={{ height: '4rem', width: '100%' }} />
        )}

        <Box paddingTop="10px">
          <AlarmSmsView />
        </Box>
      </Stack>
    </Page>
  )
}
