import { CssBaseline, ThemeProvider } from '@mui/material'
import { ColorScheme } from '@simplisafe/blueprint-web-dsl'
import React, { FC, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import { StyledSnackbarProvider } from './components/StyledSnackbarProvider/StyledSnackbarProvider.tsx'
import { useColorMode } from './hooks/useColorMode.ts'
import { theme } from './styles/theme.ts'
import { bool } from './utils/utils.ts'
import { useSetAtom } from 'jotai'
import { colorModeContainerAtom } from './atoms/atoms.ts'

const rootElement = document.getElementById('root')

if (rootElement === null) {
  throw new Error('The application could not start because the root element was not found.')
}

async function startMockServiceWorker(): Promise<void> {
  if (bool(import.meta.env.VITE_ENABLE_AUTO_MOCK) || bool(import.meta.env.VITE_ENABLE_CUSTOM_MSW_HANDLERS)) {
    const { getWorker } = await import('./msw/setup/setupForBrowser.ts')
    const worker = getWorker()
    await worker.start()
  }
}

function mountApp(rootElement: HTMLElement): void {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <AppRoot />
    </React.StrictMode>
  )
}

/**
 * App wrapper component that provides theming, the Material UI color schema and access to the styled snackbar.
 */
// eslint-disable-next-line react-refresh/only-export-components
const AppRoot: FC = () => {
  const { colorModePreference } = useColorMode()
  const colorModeContainerRef = useRef<HTMLDivElement>(null)
  const setColorModeContainerAtom = useSetAtom(colorModeContainerAtom)

  useEffect(() => {
    if (colorModeContainerRef.current !== null) {
      setColorModeContainerAtom(colorModeContainerRef.current)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ColorScheme modeOverride={colorModePreference} ref={colorModeContainerRef}>
        <CssBaseline />
        <StyledSnackbarProvider>
          <App />
        </StyledSnackbarProvider>
      </ColorScheme>
    </ThemeProvider>
  )
}

startMockServiceWorker().then(() => {
  mountApp(rootElement)
})
