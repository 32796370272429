import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Page } from '../../../components/Page'
import { AddressSchedulerForm } from './AddressSchedulerForm.tsx'

export const AddressSchedulerFormPage: FC = () => {
  const { sid: locationId } = useParams()

  return <Page title={'Address Change Scheduler'}>{locationId && <AddressSchedulerForm sid={locationId} />}</Page>
}
