import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SquareIcon from '@mui/icons-material/Square'
import { Box, Collapse, Divider, Paper, Stack, SvgIcon, Typography } from '@mui/material'
import { FC, useRef, useState } from 'react'
import { Location } from '../../api'
import ApartmentIcon from '../../assets/images/apartment.svg?react'
import BusinessIcon from '../../assets/images/business.svg?react'
import HouseIcon from '../../assets/images/house.svg?react'
import NoPropertyIcon from '../../assets/images/no_property.svg?react'
import { ResidenceType } from '../../constants'
import { getCrmUrl, getResidenceType } from '../../utils/permitUtils'
import { LocationDetails } from './LocationDetails'

interface LocationCardProps {
  location?: Location
  microView?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const LocationCard: FC<LocationCardProps> = ({ location, microView = false, onMouseEnter, onMouseLeave }) => {
  const [expanded, setExpanded] = useState(false)
  const cardRef = useRef<HTMLDivElement>(null)

  const handleToggleExpand = () => {
    if (!microView) {
      setExpanded(!expanded)
    }
  }

  const getIcon = () => {
    const createSvgIcon = (iconProps: { component: FC; testId: string }) => (
      <SvgIcon component={iconProps.component} data-testid={iconProps.testId} inheritViewBox sx={{ flexShrink: 0 }} />
    )

    if (!location) {
      return createSvgIcon({ component: NoPropertyIcon, testId: 'location-card-icon-no-property' })
    }

    switch (location.residenceType) {
      case ResidenceType.House:
        return createSvgIcon({ component: HouseIcon, testId: 'location-card-icon-house' })
      case ResidenceType.Business:
        return createSvgIcon({ component: BusinessIcon, testId: 'location-card-icon-business' })
      case ResidenceType.Apartment:
        return createSvgIcon({ component: ApartmentIcon, testId: 'location-card-icon-apartment' })
      default:
        return createSvgIcon({ component: SquareIcon, testId: 'location-card-icon-default' })
    }
  }

  if (microView) {
    return (
      <Paper
        ref={cardRef}
        data-testid="location-card-micro-view"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          blockSize: 'var(--art--location-card-height-collapsed)',
          width: '100%',
          position: 'relative',
          bgcolor: location ? 'var(--bg-location-card)' : 'var(--button-disabled-fg)',
          borderRadius: 'var(--radius-s)',
          overflow: 'hidden',
          padding: 'var(--spacing-xs) var(--spacing-xxs)',
          textAlign: 'center',
          cursor: 'pointer',
          color: 'var(--text-primary-inverted)'
        }}
      >
        <Stack spacing="var(--spacing-xs)" alignItems="center" justifyContent="center" useFlexGap>
          {getIcon()}
          <Typography
            component="span"
            data-testid="location-card-micro-street"
            sx={{
              fontSize: 'var(--size-s)',
              fontWeight: 600,
              color: 'var(--text-primary-inverted)',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {location ? location.street1 : '-'}
          </Typography>
        </Stack>
      </Paper>
    )
  }

  if (!location) {
    return (
      <Paper
        data-testid="location-card-no-location-container"
        sx={{
          blockSize: 'var(--art--location-card-height-collapsed)',
          width: '100%',
          bgcolor: 'var(--button-disabled-fg)',
          borderRadius: 'var(--radius-s)',
          overflow: 'hidden',
          padding: 'var(--spacing-xs) var(--spacing-m) var(--spacing-xs) var(--spacing-s)',
          color: 'var(--text-primary-inverted)'
        }}
      >
        <Stack direction="row" alignItems="center" spacing="var(--spacing-s)" useFlexGap>
          {getIcon()}
          <Stack
            sx={{
              minInlineSize: 0
            }}
          >
            <Typography
              component="span"
              data-testid="location-card-no-location-text"
              sx={{ fontSize: 'var(--size-l)', fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              No Location Selected
            </Typography>
            <Typography
              component="span"
              data-testid="location-card-no-location-subtext"
              sx={{
                fontSize: 'var(--size-s)',
                fontWeight: 300,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              -
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    )
  }

  return (
    <Paper
      ref={cardRef}
      data-testid="location-card-regular-view"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        blockSize: expanded ? undefined : 'var(--art--location-card-height-collapsed)',
        width: '100%',
        position: 'relative',
        bgcolor: 'var(--bg-location-card)',
        borderRadius: 'var(--radius-s)',
        overflow: 'hidden',
        paddingTop: 'var(--spacing-xs)',
        paddingRight: 'var(--spacing-m)',
        paddingBottom: 'var(--spacing-xs)',
        paddingLeft: 'var(--spacing-s)',
        color: 'var(--text-primary-inverted)'
      }}
    >
      <Stack
        direction="row"
        data-testid="location-card-header"
        spacing="var(--spacing-s)"
        onClick={handleToggleExpand}
        useFlexGap
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}
      >
        <Stack
          direction="row"
          spacing="var(--spacing-s)"
          useFlexGap
          sx={{
            alignItems: 'center',
            minInlineSize: 0
          }}
        >
          {getIcon()}
          <Stack
            sx={{
              minInlineSize: 0
            }}
          >
            <Typography
              component="span"
              data-testid="location-card-street"
              sx={{
                color: 'var(--text-primary-inverted)',
                fontSize: 'var(--size-l)',
                fontWeight: 700,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {location.street1}
            </Typography>
            <Typography
              component="span"
              data-testid="location-card-city-zip"
              sx={{
                fontSize: 'var(--size-s)',
                fontWeight: 300,
                color: 'var(--text-primary-inverted)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {`${location.city || ''}, ${location.zip || ''}`}
            </Typography>
          </Stack>
        </Stack>
        {expanded ? <ExpandLessIcon data-testid="location-card-expand-less" /> : <ExpandMoreIcon data-testid="location-card-expand-more" />}
      </Stack>

      <Collapse in={expanded} data-testid="location-card-collapse">
        <Box onClick={e => e.stopPropagation()}>
          <Divider sx={{ bgcolor: 'var(--border-location-card-divider)', my: 1 }} />
          <LocationDetails location={location} residenceType={getResidenceType(location)} crmUrl={getCrmUrl(location)} />
        </Box>
      </Collapse>
    </Paper>
  )
}
