import { useAuth0 } from '@auth0/auth0-react'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { Avatar, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import { FC, useRef, useState } from 'react'
import { useColorMode } from '../../hooks/useColorMode'
import { getUserInitials } from '../../utils/utils'
import { Stack } from '@mui/system'

type UserProfileCardProps = {
  isExpanded: boolean
}

export const UserProfileCard: FC<UserProfileCardProps> = ({ isExpanded }) => {
  const { user, logout } = useAuth0()
  const theme = useTheme()
  const { colorMode, setColorModePreference } = useColorMode()
  const userProfileMenuAnchorRef = useRef<HTMLDivElement>(null)
  const [userProfileMenuAnchor, setUserProfileMenuAnchor] = useState<null | HTMLElement>(null)

  const profileName = user?.name ?? ''
  const email = user?.email ?? ''
  const userInitials = getUserInitials(profileName)

  const toggleColorModeButtonIcon = colorMode === 'dark' ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />
  const toggleColorModeButtonLabel = colorMode === 'dark' ? 'Turn Light Mode On' : 'Turn Dark Mode On'

  const isUserProfileMenuOpen = Boolean(userProfileMenuAnchor)

  const userAvatar = (
    <Avatar
      sx={{
        backgroundColor: 'var(--bg-illustration)',
        color: 'var(--text-primary)',
        fontWeight: '700',

        // Responsive font size based on the number of initials.
        fontSize: `min(1rem, calc(2.5rem / ${userInitials.length} * 1.5))`
      }}
    >
      {userInitials}
    </Avatar>
  )

  const userProfileMenuButtonIcon = isExpanded ? isUserProfileMenuOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> : userAvatar

  /**
   * Handles a click event on the open user profile menu button.
   */
  const handleOpenUserProfileMenuButtonClick = () => {
    setUserProfileMenuAnchor(userProfileMenuAnchorRef.current)
  }

  /**
   * Handles a close event on the user profile menu.
   */
  const handleUserProfileMenuClose = () => {
    setUserProfileMenuAnchor(null)
  }

  /**
   * Handles a click event on the toggle color mode button.
   *
   * Toggles the color mode preference between light and dark, based on the current color mode.
   */
  const handleToggleColorModeButtonClick = () => {
    if (colorMode === 'dark') {
      setColorModePreference('light')
    } else {
      setColorModePreference('dark')
    }
  }

  /**
   * Handles a click event on the logout button.
   *
   * Logs the user out of the application and closes the user profile menu.
   */
  const handleLogoutButtonClick = () => {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}`
      }
    })
    handleUserProfileMenuClose()
  }

  return (
    <Box
      sx={{
        marginBlockStart: 'auto',
        padding: 'var(--spacing-xs)'
      }}
    >
      {/* This box is required to act as an anchor point for the user profile menu. */}
      <Box ref={userProfileMenuAnchorRef}>
        <Menu
          anchorEl={userProfileMenuAnchor}
          open={isUserProfileMenuOpen}
          onClose={handleUserProfileMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: isExpanded ? 'right' : 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: isExpanded ? 'right' : 'left'
          }}
          slotProps={{
            paper: {
              id: 'userProfileMenu',
              'aria-labelledby': 'open-user-profile-menu-button',
              sx: {
                // Upwards translation required to create a gap between the user profile card and its menu.
                // Also required to vertically align the menu when the user profile card is collapsed.
                translate: isExpanded ? '0 calc(-1 * var(--spacing-xs))' : 'calc(-1 * var(--spacing-xs)) calc(-1 * var(--spacing-xs))',
                minInlineSize: '14rem'
              }
            }
          }}
        >
          <MenuItem onClick={handleToggleColorModeButtonClick} data-testid="toggle-color-mode-button">
            <ListItemIcon>{toggleColorModeButtonIcon}</ListItemIcon>
            <ListItemText>{toggleColorModeButtonLabel}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogoutButtonClick} data-testid="logout-button">
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Sign out</ListItemText>
          </MenuItem>
        </Menu>

        <Stack
          direction="row"
          useFlexGap
          sx={{
            blockSize: isExpanded ? '5.5rem' : '4.5rem',
            minInlineSize: 0,
            columnGap: 'var(--spacing-s)',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingInline: isExpanded ? 'var(--spacing-m)' : 'var(--spacing-xs)',
            paddingBlock: isExpanded ? 'var(--spacing-m)' : 'var(--spacing-xs)',
            backgroundColor: 'var(--bg-surface-low)',
            borderRadius: 'var(--radius-s)',
            transition: theme.transitions.create('block-size', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
          }}
        >
          {isExpanded && (
            <Stack
              direction="row"
              useFlexGap
              sx={{
                minInlineSize: 0,
                columnGap: 'var(--spacing-s)'
              }}
            >
              {userAvatar}
              <Stack useFlexGap sx={{ minInlineSize: 0, rowGap: 'var(--spacing-xxs)' }}>
                <Typography
                  component="span"
                  title={profileName}
                  sx={{
                    color: 'var(--text-primary)',
                    fontSize: 'var(--size-m)',
                    fontWeight: '500',
                    lineHeight: 'var(--line-height-m, 1.125rem)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {profileName}
                </Typography>
                <Typography
                  component="span"
                  title={email}
                  sx={{
                    color: 'var(--text-secondary)',
                    fontSize: 'var(--size-s)',
                    fontWeight: '400',
                    lineHeight: '0.75rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {email}
                </Typography>
              </Stack>
            </Stack>
          )}
          <IconButton
            onClick={handleOpenUserProfileMenuButtonClick}
            data-testid="open-user-profile-menu-button"
            title="Show user options"
            aria-controls={isUserProfileMenuOpen ? 'userProfileMenu' : undefined}
            aria-expanded={isUserProfileMenuOpen ? 'true' : undefined}
            aria-haspopup="true"
          >
            {userProfileMenuButtonIcon}
          </IconButton>
        </Stack>
      </Box>
    </Box>
  )
}
