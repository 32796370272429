import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { useAtomValue } from 'jotai'
import { FC, useCallback, useMemo, useState } from 'react'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { addressValidationResultsAtom, currentLocationChangeAtom, selectedAddressValidationResultAtom } from '../../../atoms'
import { Page } from '../../../components/Page'
import { SELECTED_ADDRESS_UNVERIFIED } from '../../../constants'
import { AddressConfirmationDialog } from './AddressConfirmationDialog'

const AddressConfirmationPage: FC = () => {
  const navigate = useNavigate()
  const addressValidationResults = useAtomValue(addressValidationResultsAtom)
  const selectedAddressValidationResult = useAtomValue(selectedAddressValidationResultAtom)
  const currentLocationChange = useAtomValue(currentLocationChangeAtom)
  const [isConfirmAddressDialogOpen, setIsConfirmAddressDialogOpen] = useState(false)

  const handleConfirm = () => {
    if (selectedAddressValidationResult === SELECTED_ADDRESS_UNVERIFIED) {
      navigate(`../time-zone`)
    } else {
      setIsConfirmAddressDialogOpen(true)
    }
  }

  const handleConfirmAddress = () => {
    navigate(`../date-picker`)
  }

  const criticalIssues = useMemo(
    () => addressValidationResults?.candidates.flatMap(candidate => candidate.issues.filter(issue => issue.severity === 'Critical')) || [],
    [addressValidationResults]
  )

  const warnings = useMemo(
    () => addressValidationResults?.candidates.flatMap(candidate => candidate.issues.filter(issue => issue.severity === 'Warning')) || [],
    [addressValidationResults]
  )

  const getIssueTitle = useCallback(() => {
    if (selectedAddressValidationResult === SELECTED_ADDRESS_UNVERIFIED) {
      return 'Confirm Address'
    } else if (criticalIssues.length) {
      return 'This address cannot be monitored'
    } else {
      return 'Confirm Address'
    }
  }, [selectedAddressValidationResult, criticalIssues])

  const getIssueDescription = useCallback(() => {
    if (selectedAddressValidationResult === SELECTED_ADDRESS_UNVERIFIED) {
      return "We couldn't verify this address. Review it carefully with the customer before continuing."
    } else if (criticalIssues.length) {
      return criticalIssues[0]?.description.external
    } else if (warnings.length) {
      return warnings.map(issue => issue.description.external).join(' ')
    }
    return null
  }, [selectedAddressValidationResult, criticalIssues, warnings])

  // 1. If selectedAddressValidationResult is SELECTED_ADDRESS_VALIDATION_UNDEFINED: show Edit Address and Continue
  // 2. If not SELECTED_ADDRESS_VALIDATION_UNDEFINED and has warning issues: show Edit Address and Confirm
  // 3. If not SELECTED_ADDRESS_VALIDATION_UNDEFINED and has critical issues: show Exit button only
  const showEditAndConfirmButtons = !!(selectedAddressValidationResult === SELECTED_ADDRESS_UNVERIFIED || (warnings.length && !criticalIssues.length))
  const showExitButton = !!(selectedAddressValidationResult !== SELECTED_ADDRESS_UNVERIFIED && criticalIssues.length)

  return (
    <Page title={'Address Change Scheduler'}>
      <Stack flexGrow={1} useFlexGap sx={{ bgcolor: 'var(--bg-surface)', boxShadow: 1, borderRadius: 'var(--radius-s)' }}>
        <Stack>
          <Typography fontSize="var(--size-h1)" fontWeight="700" sx={{ padding: 'var(--spacing-s)' }}>
            Scheduled Address Changes
          </Typography>
        </Stack>
        <Stack flexDirection={'column'} alignItems="flex-start" justifyContent="flex-start" sx={{ padding: 'var(--spacing-m) var(--spacing-l)' }}>
          {selectedAddressValidationResult !== SELECTED_ADDRESS_UNVERIFIED && (
            <ReportProblemIcon color="error" sx={{ padding: 'var(--spacing-sx)' }} />
          )}
          <Typography fontSize="var(--size-h2)" fontWeight="500" data-testid="address-confirmation-issue-message">
            {getIssueTitle()}
          </Typography>
          <Typography fontSize="var(--size-l)" data-testid="address-confirmation-issue-message-description">
            {getIssueDescription()}
          </Typography>
          <Box sx={{ marginTop: 'var(--spacing-xs)' }}>
            <Typography fontSize="var(--size-l)">You entered:</Typography>
            <Typography fontSize="var(--size-l)" data-testid="address-confirmation-address-street">
              {currentLocationChange?.address?.street1}
            </Typography>
            <Typography fontSize="var(--size-l)" data-testid="address-confirmation-address-city-state">
              {currentLocationChange?.address?.city}, {currentLocationChange?.address?.state}
            </Typography>
            <Typography fontSize="var(--size-l)" data-testid="address-confirmation-address-zip">
              {currentLocationChange?.address?.postalCode}
            </Typography>
          </Box>
        </Stack>

        <Divider />

        <Stack data-testid="address-confirmation-actions" direction="row" justifyContent="flex-end" spacing={2} sx={{ padding: 'var(--spacing-m)' }}>
          {showExitButton && (
            <Button component={ReactRouterLink} to="../validation-results" type="button" variant="outlined" data-testid="address-confirmation-exit">
              Exit
            </Button>
          )}
          {showEditAndConfirmButtons && (
            <>
              <Button component={ReactRouterLink} to="../address-form" type="button" data-testid="address-confirmation-edit">
                Edit Address
              </Button>
              <Button type="button" data-testid="address-confirmation-confirm" onClick={handleConfirm}>
                {selectedAddressValidationResult === SELECTED_ADDRESS_UNVERIFIED ? 'Continue' : 'Confirm'}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      <AddressConfirmationDialog
        isOpen={isConfirmAddressDialogOpen}
        onClose={() => {
          setIsConfirmAddressDialogOpen(false)
        }}
        onConfirm={handleConfirmAddress}
        customerAddress={{
          street1: addressValidationResults?.address?.street1,
          city: addressValidationResults?.address?.city,
          zip: addressValidationResults?.address?.zip
        }}
      />
    </Page>
  )
}

export { AddressConfirmationPage }
