import { FC, RefObject, useRef } from 'react'
import { Box, Paper, Popper } from '@mui/material'
import { Location } from '../../api'
import { LocationDetails } from './LocationDetails'
import { getCrmUrl, getResidenceType } from '../../utils/permitUtils'
import { useAtomValue } from 'jotai'
import { colorModeContainerAtom } from '../../atoms'

interface LocationCardPopperProps {
  location: Location
  open: boolean
  anchorRef: RefObject<HTMLElement | null>
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const LocationCardPopper: FC<LocationCardPopperProps> = ({ location, open, anchorRef, onMouseEnter, onMouseLeave }) => {
  const popperRef = useRef<HTMLDivElement>(null)
  const colorModeContainer = useAtomValue(colorModeContainerAtom)

  if (!location || !open || !colorModeContainer) return null

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      placement="right-end"
      container={colorModeContainer}
      sx={{
        width: '23rem',
        borderRadius: 'var(--radius-s)',
        zIndex: 1
      }}
    >
      <Box ref={popperRef} data-testid="location-card-popper" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Paper
          sx={{
            bgcolor: 'var(--bg-location-card)',
            color: 'var(--text-primary-inverted)',
            borderRadius: 'var(--radius-s)',
            overflow: 'hidden',
            padding: 'var(--spacing-s)'
          }}
        >
          <LocationDetails location={location} residenceType={getResidenceType(location)} crmUrl={getCrmUrl(location)} />
        </Paper>
      </Box>
    </Popper>
  )
}
