import { Box, Button, Divider, FormControlLabel, List, ListItem, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { useAtom, useAtomValue } from 'jotai'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { addressValidationResultsAtom, currentLocationChangeAtom, selectedAddressValidationResultAtom } from '../../../atoms'
import { Page } from '../../../components/Page'
import { SELECTED_ADDRESS_UNVERIFIED } from '../../../constants'

/**
 * Form control's value when the user hasn't selected any address option yet.
 */
const NO_ADDRESS_OPTION_SELECTED = ''

type ValidationResultsForm = {
  /*
   * Due to how the form works, index numbers are serialized as strings and unselected values are represented as an empty string.
   * When processing those values, they should be converted to the underlying numbers or undefined, respectively.
   */
  addressOption: string
}

/**
 * Component that represents a single address option that the user can select in the form.
 */
const AddressOption: FC<{
  value: number | 'UNVERIFIED'
  street1: string
  street2: string
  city: string
  state: string
  zip: string
}> = ({ value, street1, street2, city, state, zip }) => {
  const optionTestId = value === SELECTED_ADDRESS_UNVERIFIED ? 'unverified-address-option' : `suggested-address-${value}-option`

  return (
    <FormControlLabel
      data-testid={optionTestId}
      value={value}
      control={<Radio />}
      label={
        <Typography component="span" fontSize="var(--size-l)">
          {street1}
          {street2 && (
            <>
              <br />
              {street2}
            </>
          )}
          {city && state && (
            <>
              <br />
              {city}, {state}
            </>
          )}
          {zip && (
            <>
              <br />
              {zip}
            </>
          )}
        </Typography>
      }
    />
  )
}

/**
 * Page component that allows the user to select a suggested address or use the original address to proceed with the
 * addition or modification of a location change in the Address Scheduler.
 */
export const ValidationResultsPage: FC = () => {
  const navigate = useNavigate()
  const currentLocationChange = useAtomValue(currentLocationChangeAtom)
  const addressValidationResults = useAtomValue(addressValidationResultsAtom)
  const [selectedAddressValidationResult, setSelectedAddressValidationResult] = useAtom(selectedAddressValidationResultAtom)
  const { control, handleSubmit, watch } = useForm<ValidationResultsForm>({
    defaultValues: {
      // If the user has already selected an address, pre-select it. Otherwise, default to an empty string.
      addressOption: selectedAddressValidationResult !== undefined ? String(selectedAddressValidationResult) : NO_ADDRESS_OPTION_SELECTED
    }
  })

  const selectedAddressOption = watch('addressOption')
  const suggestedAddresses = addressValidationResults?.candidates ?? []
  const unverifiedAddressFromForm = currentLocationChange?.address
  const shouldEnableSubmitButton = selectedAddressOption !== NO_ADDRESS_OPTION_SELECTED
  const textForEditButton = selectedAddressOption === NO_ADDRESS_OPTION_SELECTED ? 'Edit' : 'Edit Original Address'

  /**
   * Handles the submit event on the form. It defines the atom configuration and the next page to navigate to based on the user's selection.
   */
  const handleFormSubmission: SubmitHandler<ValidationResultsForm> = ({ addressOption }) => {
    // If the user hasn't selected any option, do nothing
    if (addressOption === NO_ADDRESS_OPTION_SELECTED) {
      return
    }

    // If the user insists on using the unverified address from the form, redirect to the confirmation page
    if (addressOption === SELECTED_ADDRESS_UNVERIFIED) {
      setSelectedAddressValidationResult(SELECTED_ADDRESS_UNVERIFIED)
      navigate(`../confirmation`)
      return
    }

    // Suggested address indexes are serialized as strings, so they need to be converted back to numbers
    const deserializedAddressOption = Number(addressOption)

    const selectedSuggestedAddress = suggestedAddresses.at(deserializedAddressOption)

    // If the selected suggested address was not found, do nothing
    if (selectedSuggestedAddress === undefined) {
      // TODO: This scenario should not happen, decide what to do in this case
      return
    }

    setSelectedAddressValidationResult(deserializedAddressOption)

    if (selectedSuggestedAddress.issues.length === 0) {
      // If the suggested address has no issues, redirect to date picker page
      navigate(`../date-picker`)
    } else {
      // If the suggested address does have issues, redirect to the confirmation page
      navigate(`../confirmation`)
    }
  }

  return (
    <Page title={'Address Change Scheduler'}>
      <Box
        sx={{
          display: 'contents',
          'form, fieldset, label': {
            border: 'none',
            padding: 0,
            margin: 0
          }
        }}
      >
        <form data-testid="validation-results-form" onSubmit={handleSubmit(handleFormSubmission)}>
          <Stack bgcolor="var(--bg-surface)" boxShadow="1" borderRadius="var(--radius-s)">
            <Stack component="section" padding="var(--spacing-s)" spacing="var(--spacing-xxl)" useFlexGap>
              <Typography component="h2" fontSize="var(--size-h1)" fontWeight="700" color="text.primary">
                Schedule An Address Change
              </Typography>
              <Stack component="section" spacing="var(--spacing-m)" paddingInlineStart="var(--spacing-xs)" useFlexGap>
                <Stack>
                  <Typography component="h3" fontSize="var(--size-h2)" fontWeight="500" color="text.primary">
                    Confirm Address
                  </Typography>
                  <Typography component="p" color="text.primary" maxWidth="34.5rem">
                    Consider using a suggested address to help our agents request dispatch to the right place in an emergency.
                  </Typography>
                </Stack>
                <fieldset>
                  <Controller
                    name="addressOption"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup aria-label="Address" {...field}>
                        <Stack spacing={'var(--spacing-s)'} useFlexGap>
                          <Stack component="section" spacing="var(--spacing-xs)" useFlexGap>
                            <Typography component="h4" fontWeight="700">
                              You entered:
                            </Typography>
                            <List disablePadding>
                              <ListItem disablePadding>
                                <AddressOption
                                  value={SELECTED_ADDRESS_UNVERIFIED}
                                  street1={unverifiedAddressFromForm?.street1 ?? ''}
                                  street2={unverifiedAddressFromForm?.street2 ?? ''}
                                  city={unverifiedAddressFromForm?.city ?? ''}
                                  state={unverifiedAddressFromForm?.state ?? ''}
                                  zip={unverifiedAddressFromForm?.postalCode ?? ''}
                                />
                              </ListItem>
                            </List>
                          </Stack>
                          <Stack component="section" spacing="var(--spacing-xs)" useFlexGap>
                            <Typography component="h4" fontWeight="700">
                              Suggested addresses:
                            </Typography>
                            <List disablePadding sx={{ display: 'flex', flexDirection: 'column', rowGap: 'var(--spacing-xs)' }}>
                              {suggestedAddresses.map((suggestedAddress, index) => (
                                <ListItem key={index} disablePadding>
                                  <AddressOption
                                    value={index}
                                    street1={suggestedAddress.street1 ?? ''}
                                    street2={suggestedAddress.street2 ?? ''}
                                    city={''}
                                    state={''}
                                    zip={''}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Stack>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </fieldset>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent="flex-end" padding="var(--spacing-m)" spacing="var(--spacing-m)" useFlexGap>
              <Button component={ReactRouterLink} to="../address-form" type="button" variant="outlined" color="primary" data-testid="edit-button">
                {textForEditButton}
              </Button>
              <Button type="submit" disabled={!shouldEnableSubmitButton} variant="contained" color="primary" data-testid="submit-button">
                Use This Address
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Page>
  )
}
