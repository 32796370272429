import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation as useLocationData } from '../../api/apiQueries.ts'
import { SIDEBAR_JURISDICTION_DETAILS } from '../../constants'
import { AgencyType } from '../../constants/AgencyType.ts'
import { displayAgencyPermitRequirement } from '../../utils/permitUtils.ts'
import { AppFormLink } from './AppFormLink.tsx'
import { OrdinanceLink } from './OrdinanceLink.tsx'
import {
  getCallVerification,
  getLastResearchedDate,
  getNumberOfFalseAlarmsBeforeFine,
  getNumberOfFalseAlarmsBeforeSuspension,
  getPermitResponsible,
  getRenewalCadence,
  getRenewalRequirement,
  getRenewalResponsible,
  isPrivateResponseRequired
} from './jurisdictionUtils.ts'

/**
 * JurisdictionDetailsTable component.
 * Renders a table filled with jurisdiction-related data.
 */
export const JurisdictionDetailsTable: FC = () => {
  const { sid: locationId } = useParams()
  const locationQuery = useLocationData(locationId)
  
  const location = locationQuery.data
  const jurisdiction = location?.jurisdiction

  return (
    <>
      {jurisdiction ? (
        <Paper data-testid={'jurisdiction-details'}>
          <Typography variant={'sectionHeading'} p={'1rem'}>
            {SIDEBAR_JURISDICTION_DETAILS}
          </Typography>
          <TableContainer data-testid={'jurisdiction-details-table'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Police
                  </TableCell>
                  <TableCell align="left" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Fire
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Jurisdiction Name
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }} data-testid={'jurisdiction-name'}>
                    {jurisdiction.name}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Jurisdiction Id
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.id}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Ordinance
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem' }}>
                    <OrdinanceLink location={location} agencyType={AgencyType.police} />
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem' }}>
                    <OrdinanceLink location={location} agencyType={AgencyType.fire} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Permit Application
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem' }}>
                    <AppFormLink location={location} agencyType={AgencyType.police} />
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem' }}>
                    <AppFormLink location={location} agencyType={AgencyType.police} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Permit Requirement
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }} data-testid={'permit-requirement'}>
                    {displayAgencyPermitRequirement(location, AgencyType.police)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {displayAgencyPermitRequirement(location, AgencyType.fire)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Raw Permit Requirement
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.police]?.permitRequirements.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.police]?.permitRequirements.raw.description}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.fire]?.permitRequirements.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.fire]?.permitRequirements.raw.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Permit Responsible
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getPermitResponsible(jurisdiction)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getPermitResponsible(jurisdiction)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Raw permit responsible
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.police]?.permitResponsible.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.police]?.permitResponsible.raw.description}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.fire]?.permitResponsible.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.fire]?.permitResponsible.raw.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Renewal Requirement
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getRenewalRequirement(jurisdiction, AgencyType.police)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getRenewalRequirement(jurisdiction, AgencyType.fire)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Renewal Responsible
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getRenewalResponsible(jurisdiction)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getRenewalResponsible(jurisdiction)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Raw Renewal Responsible
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.police]?.renewalResponsible.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.police]?.renewalResponsible.raw.description}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.fire]?.renewalResponsible.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.fire]?.renewalResponsible.raw.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Renewal Cadence
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getRenewalCadence(jurisdiction, AgencyType.police)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getRenewalCadence(jurisdiction, AgencyType.fire)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Last Researched
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getLastResearchedDate(jurisdiction, AgencyType.police)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getLastResearchedDate(jurisdiction, AgencyType.fire)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Private Response
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {isPrivateResponseRequired(jurisdiction, AgencyType.police)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {isPrivateResponseRequired(jurisdiction, AgencyType.fire)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Verified Response
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.police]?.verifiedResponseRequirements?.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.police]?.verifiedResponseRequirements?.raw.description}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.fire]?.verifiedResponseRequirements?.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.fire]?.verifiedResponseRequirements?.raw.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Call Verification
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getCallVerification(jurisdiction, AgencyType.police)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getCallVerification(jurisdiction, AgencyType.fire)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Multiple Alarm Triggers Required
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.police]?.multipleAlarmTriggersRequirements?.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.police]?.multipleAlarmTriggersRequirements?.raw.description}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.fire]?.multipleAlarmTriggersRequirements?.raw.code} -{' '}
                    {jurisdiction.agencyRules[AgencyType.fire]?.multipleAlarmTriggersRequirements?.raw.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    False Alarms Before Suspension
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getNumberOfFalseAlarmsBeforeSuspension(jurisdiction, AgencyType.police)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getNumberOfFalseAlarmsBeforeSuspension(jurisdiction, AgencyType.fire)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Evaluation Period for Suspension due to False Alarms
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.police]?.falseAlarmRules?.suspendable?.raw?.period ?? 'Not available'}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.fire]?.falseAlarmRules?.suspendable?.raw?.period ?? 'Not available'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    False Alarms Allowed Before Fine
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getNumberOfFalseAlarmsBeforeFine(jurisdiction, AgencyType.police)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {getNumberOfFalseAlarmsBeforeFine(jurisdiction, AgencyType.fire)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Evaluation Period for Fines due to False Alarms
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.police]?.falseAlarmRules?.fineable?.raw?.period ?? 'Not available'}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.fire]?.falseAlarmRules?.fineable?.raw?.period ?? 'Not available'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ textTransform: 'uppercase', fontSize: '.875rem', fontWeight: '500' }}>
                    Comment
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.police]?.permitAdditionalNotes}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '1rem', fontWeight: '300' }}>
                    {jurisdiction.agencyRules[AgencyType.fire]?.permitAdditionalNotes}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper>
          <Typography fontSize={'1.5rem'} fontWeight={500} component="h2" p={'1rem'}>
            {SIDEBAR_JURISDICTION_DETAILS}
          </Typography>
          <TableContainer>
            <Table>
              <TableHead></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center" data-testid="no-data-message" sx={{ paddingBottom: '5rem' }}>
                    <Box sx={{ fontSize: '1.5rem', fontWeight: '500' }}>No Data Available</Box>
                    <Box sx={{ fontSize: '1.5rem', fontWeight: '300' }}>Pending assignment of jurisdiction.</Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  )
}
