import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { Box } from '@mui/material'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'
import { Stack } from '@mui/system'
import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from '../../api/apiQueries'
import AddressChangeSchedulerIcon from '../../assets/images/icons/address-change-scheduler-icon.svg?react'
import AlarmSmsReviewIcon from '../../assets/images/icons/alarm-sms-review-icon.svg?react'
import LocationFeatureViewerIcon from '../../assets/images/icons/location-feature-viewer-icon.svg?react'
import PermitManagementIcon from '../../assets/images/icons/permit-management-icon.svg?react'
import SimplisafeLogoDarkMode from '../../assets/images/simplisafe-logo-wordmark-dark-mode.svg'
import SimplisafeLogoLightMode from '../../assets/images/simplisafe-logo-wordmark-light-mode.svg'
import {
  ADDRESS_SCHEDULER_APPLET_SCOPES,
  ALARM_SMS_REVIEW_APPLET_SCOPES,
  LOCATION_FEATURES_APPLET_SCOPES,
  PERMITTING_APPLET_SCOPES
} from '../../constants'
import { useColorMode } from '../../hooks/useColorMode'
import { LocationCard, LocationCardPopper } from '../LocationCard'
import { UserProfileCard } from '../UserProfileCard/UserProfileCard'
import { NavItem } from './NavItem/NavItem'

const expandedDrawerSize = '25rem'
const collapsedDrawerSize = 'var(--art--sidenav-width-collapsed)'

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: 'var(--spacing-s)',
  paddingBlock: 'var(--spacing-m)',
  paddingInline: 'var(--spacing-s)'
}))

export default function SideNav() {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(true)
  const [showPopper, setShowPopper] = useState(false)
  const { sid: locationId } = useParams()
  const locationQuery = useLocation(locationId)
  const location = locationQuery.data
  const locationCardRef = useRef<HTMLDivElement>(null)
  const hidePopperTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const { colorMode } = useColorMode()

  const simplisafeLogoUrl = colorMode === 'light' ? SimplisafeLogoLightMode : SimplisafeLogoDarkMode

  const buildNavItemHref = (navItemPath: string) => {
    if (locationId !== undefined) {
      return `location/${locationId}/${navItemPath}` as const
    } else {
      return `location/${navItemPath}` as const
    }
  }

  const handleDrawerStateChange = () => {
    setIsExpanded(!isExpanded)
  }

  const handleLocationCardMouseEnter = () => {
    if (hidePopperTimeoutRef.current) {
      clearTimeout(hidePopperTimeoutRef.current)
      hidePopperTimeoutRef.current = null
    }
    setShowPopper(true)
  }

  const handleLocationCardMouseLeave = () => {
    // a delay before hiding the popper
    hidePopperTimeoutRef.current = setTimeout(() => {
      setShowPopper(false)
      hidePopperTimeoutRef.current = null
    }, 500)
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={true}
      slotProps={{
        paper: {
          elevation: 2
        }
      }}
      sx={{
        width: isExpanded ? expandedDrawerSize : collapsedDrawerSize,
        flexShrink: 0,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        '& .MuiDrawer-paper': {
          width: isExpanded ? expandedDrawerSize : collapsedDrawerSize,
          overflowX: 'hidden',
          borderRight: 'none',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
        }
      }}
    >
      <Stack
        sx={{
          paddingInline: 'var(--spacing-xs)'
        }}
      >
        <DrawerHeader
          sx={{
            paddingInlineStart: isExpanded ? 'var(--spacing-m)' : undefined,
            paddingInlineEnd: isExpanded ? 'var(--spacing-s)' : undefined,
            transition: theme.transitions.create('padding', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            })
          }}
        >
          <img
            src={simplisafeLogoUrl}
            alt="SimpliSafe logo"
            style={{
              display: isExpanded ? 'block' : 'none',
              opacity: isExpanded ? 1 : 0,
              transition: `
                display ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp} allow-discrete,
                opacity ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}
              `
            }}
          />
          <IconButton
            title={isExpanded ? 'Collapse drawer' : 'Expand drawer'}
            aria-label={isExpanded ? 'Collapse drawer' : 'Expand drawer'}
            onClick={handleDrawerStateChange}
          >
            {isExpanded ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
      </Stack>

      <Box ref={locationCardRef} sx={{ padding: 'var(--spacing-m) var(--spacing-xs) var(--spacing-xxs) var(--spacing-xs)' }}>
        <LocationCard
          location={location}
          microView={!isExpanded}
          onMouseEnter={handleLocationCardMouseEnter}
          onMouseLeave={handleLocationCardMouseLeave}
        />
        {!isExpanded && location && (
          <LocationCardPopper
            location={location}
            open={showPopper}
            anchorRef={locationCardRef}
            onMouseEnter={() => {
              if (hidePopperTimeoutRef.current) {
                clearTimeout(hidePopperTimeoutRef.current)
                hidePopperTimeoutRef.current = null
              }
            }}
            onMouseLeave={handleLocationCardMouseLeave}
          />
        )}
      </Box>

      <NavItem
        testId="permits-tab"
        isOpen={isExpanded}
        scopes={PERMITTING_APPLET_SCOPES}
        to={buildNavItemHref('permits')}
        label={'Permit Management'}
        icon={<PermitManagementIcon style={{ width: 'var(--base-values-3x)', height: 'var(--base-values-3x)' }} />}
      />
      <NavItem
        testId="alarm-sms-tab"
        isOpen={isExpanded}
        scopes={ALARM_SMS_REVIEW_APPLET_SCOPES}
        to={buildNavItemHref('alarm-sms-review')}
        label={'Alarm SMS Review'}
        icon={<AlarmSmsReviewIcon style={{ width: 'var(--base-values-3x)', height: 'var(--base-values-3x)' }} />}
      />
      <NavItem
        testId="location-features-tab"
        isOpen={isExpanded}
        scopes={LOCATION_FEATURES_APPLET_SCOPES}
        to={buildNavItemHref('location-features')}
        label={'Location Feature Viewer'}
        icon={<LocationFeatureViewerIcon style={{ width: 'var(--base-values-3x)', height: 'var(--base-values-3x)' }} />}
      />
      <NavItem
        testId="address-scheduler-tab"
        isOpen={isExpanded}
        scopes={ADDRESS_SCHEDULER_APPLET_SCOPES}
        to={buildNavItemHref('address-scheduler')}
        label={'Address Scheduler'}
        icon={<AddressChangeSchedulerIcon style={{ width: 'var(--base-values-3x)', height: 'var(--base-values-3x)' }} />}
      />
      <UserProfileCard isExpanded={isExpanded} />
    </Drawer>
  )
}
