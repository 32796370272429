import {
  AddressCandidate,
  AddressValidation,
  AddressValidationCorrection,
  AddressValidationIssue,
  CreateLocationChange,
  LocationChangeAddress
} from '../../../api/models'
import { ISSUE_AND_CORRECTION_MAPPINGS } from '../../../constants'

export type IssueCorrectionMessage = {
  message: string
  field: string
  errorText: string
}

export function mapFormDataToLocationModel(formData: Partial<LocationChangeAddress>): CreateLocationChange {
  return {
    effectiveAt: '',
    address: {
      street1: formData.street1!,
      street2: formData.street2,
      city: formData.city!,
      state: formData.state!,
      postalCode: formData.postalCode!,
      country: 'US',
      propertyType: formData.propertyType!,
      timeZone: 'Unknown'
    }
  }
}

export function mapLocationChangeAddressToRealtorAddress(address: Omit<LocationChangeAddress, 'timeZone'>): AddressValidation {
  return {
    street1: address.street1,
    street2: address?.street2 ?? '',
    city: address.city,
    state: address.state,
    zip: address.postalCode,
    country: address.country,
    residenceType: address.propertyType
  }
}

export function mapRealtorAddressToLocationChangeAddress(address: AddressCandidate): Partial<LocationChangeAddress> {
  return {
    street1: address.street1 ?? '',
    street2: address?.street2 ?? '',
    city: address.city ?? '',
    state: address.state ?? '',
    postalCode: address.zip ?? '',
    country: address.country === 'US' ? 'US' : 'GB'
  }
}

export function mapIssueTypeToUserFriendlyMessage(issueOrCorrection: AddressValidationIssue | AddressValidationCorrection): IssueCorrectionMessage {
  const { type } = issueOrCorrection

  if (ISSUE_AND_CORRECTION_MAPPINGS[type]) {
    return ISSUE_AND_CORRECTION_MAPPINGS[type]
  }

  // Default
  return {
    message: 'Unknown issue.',
    field: 'other',
    errorText: 'Unknown issue. Please check the address and try again.'
  }
}
