import { Box, Divider, Link, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { DetailRow } from './DetailRow'
import { Location } from '../../api'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

interface LocationDetailsProps {
  location: Location
  residenceType?: string
  crmUrl?: string
}

export const LocationDetails: FC<LocationDetailsProps> = ({ location, residenceType, crmUrl }) => {
  const addressLines = useMemo(() => {
    const lines: string[] = []
    if (location) {
      lines.push(location.street1 || '-')
      if (location.street2) {
        lines.push(location.street2)
      }
      lines.push(`${location.city || '-'}, ${location.state || '-'} ${location.zip || '-'}`)
    }
    return lines
  }, [location])

  return (
    <Box>
      <DetailRow label="Location" value={location.name} />
      <DetailRow label="Type" value={residenceType} />
      <DetailRow label="Address" value={addressLines} />
      <DetailRow label="County" value={location?.county} />
      <Divider sx={{ bgcolor: 'var(--border-location-card-divider)', my: 1 }} />
      <DetailRow label="Base Station Serial #" value={location?.baseStationSerialNumber} />
      <DetailRow label="SID" value={location?.id} />
      <DetailRow label="UID" value={location?.userId ? String(location.userId) : ''} />
      <DetailRow label="Email" value={location?.user?.emailAddress} />
      <Divider sx={{ bgcolor: 'var(--border-location-card-divider)', my: 1 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 'var(--spacing-xs)' }}>
        <Typography sx={{ color: 'var(--text-primary-inverted)' }} fontSize="var(--size-s)" fontWeight={300}>
          To make changes, please go to the
        </Typography>
        <Link
          target="_blank"
          href={crmUrl || '#'}
          sx={{
            color: 'var(--text-primary-inverted)',
            fontSize: 'var(--size-s)',
            fontWeight: 300,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          CRM
          <ArrowForwardIcon sx={{ fontSize: 'var(--size-s)', ml: 0.5 }} />
        </Link>
      </Box>
    </Box>
  )
}
