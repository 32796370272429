import { Stack } from '@mui/material'
import { useSetAtom } from 'jotai'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LocationChange } from '../../../api'
import { useLocationChanges } from '../../../api/apiQueries.ts'
import { addressValidationResultsAtom, currentLocationChangeAtom, selectedAddressValidationResultAtom } from '../../../atoms'
import { Page } from '../../../components/Page'
import { AddressChangesList } from './AddressChangesList.tsx'
import { OverviewPreview } from './OverviewPreview.tsx'

export const OverviewPage: FC = () => {
  const { sid: locationId } = useParams()
  const navigate = useNavigate()
  const locationChanges = useLocationChanges(locationId)
  const setCurrentLocationChange = useSetAtom(currentLocationChangeAtom)

  const setAddressValidationResults = useSetAtom(addressValidationResultsAtom)
  const setSelectedAddressValidationResult = useSetAtom(selectedAddressValidationResultAtom)

  useEffect(() => {
    setAddressValidationResults(undefined)
    setSelectedAddressValidationResult(undefined)
  }, [setAddressValidationResults, setSelectedAddressValidationResult])

  const hasPendingAddressChange = locationChanges.data?.some(change => change.state === 'pending')

  const handleScheduleAddress = () => {
    navigate(`../address-form`)
  }

  const handleScheduleEdit = (locationChange: LocationChange) => {
    setCurrentLocationChange(locationChange)
    navigate(`../date-picker`)
  }

  return (
    <Page title={'Address Change Scheduler'}>
      {!locationChanges.isLoading && (
        <Stack flexGrow={1} spacing={'2rem'} useFlexGap data-testid="address-scheduler-content">
          {!hasPendingAddressChange && <OverviewPreview onScheduleNewAddress={handleScheduleAddress} />}
          {locationChanges.data && <AddressChangesList onEditClicked={handleScheduleEdit} addressChanges={locationChanges.data} />}
        </Stack>
      )}
    </Page>
  )
}
