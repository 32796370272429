import { Grid2 as Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

/**
 * Utility function for copying text to clipboard.
 */
const handleCopyText = (text: string) => {
  navigator.clipboard.writeText(text).catch(err => {
    console.error('Could not copy text: ', err)
  })
}

interface DetailRowProps {
  label: string
  value?: string | string[]
}

export const DetailRow: FC<DetailRowProps> = ({ label, value }) => {
  const [rowHover, setRowHover] = useState(false)
  const lines = useMemo(() => {
    const emptyText = 'N/A'
    if (Array.isArray(value)) {
      return value.length ? value : [emptyText]
    } else {
      return [value || emptyText]
    }
  }, [value])

  const copyValue = lines.join('\n')

  return (
    <Grid
      container
      sx={{ position: 'relative', marginBottom: 'var(--spacing-xs)' }}
      onMouseEnter={() => setRowHover(true)}
      onMouseLeave={() => setRowHover(false)}
    >
      <Grid size={{ xs: 3 }}>
        <Typography fontSize="var(--size-l)" fontWeight={500}>
          {label}
        </Typography>
      </Grid>
      <Grid size={{ xs: 8 }}>
        {lines.map((line, index) => (
          <Typography key={index} fontSize="var(--size-l)" fontWeight={300}>
            {line}
          </Typography>
        ))}
      </Grid>
      <Grid size={{ xs: 1 }}>
        {rowHover && value !== undefined && value !== '' && (
          <Tooltip title="Copy to clipboard">
            <IconButton
              size="small"
              onClick={e => {
                e.stopPropagation()
                handleCopyText(copyValue as string)
              }}
              sx={{
                position: 'absolute',
                right: 'var(--base-values-1x)',
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'var(--text-primary-inverted)',
                opacity: 0.7,
                '&:hover': {
                  opacity: 1
                }
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
}
