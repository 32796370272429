import { Box, BoxProps, Stack } from '@mui/material'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { useNavigate, useParams, useLocation as useRouterLocation } from 'react-router-dom'
import { useLocation } from '../../api/apiQueries.ts'
import { LocationSearch } from '../../pages/PermitPage/LocationSearch.tsx'
import { ErrorScreen } from '../ErrorScreen/ErrorScreen.tsx'
import { Skeleton } from '../Skeleton/Skeleton.tsx'
import { Header } from './Header.tsx'

/**
 * Props for the `Page` component.
 */
export type PageProps = PropsWithChildren<
  BoxProps & {
    /**
     * The title of the page, shown in a level 1 heading at the top. If not provided, no header will be shown.
     */
    title?: string
    /**
     * Whether to show the location search bar at the top of the page. Defaults to `true`.
     */
    showLocationSearchBar?: boolean
    /**
     * Whether to show the children when no location is provided in the URL. Defaults to `false`.
     */
    showChildrenWhenMissingLocation?: boolean
  }
>

export const Page: FC<PageProps> = ({
  title,
  showLocationSearchBar: shouldShowLocationSearchBar = true,
  showChildrenWhenMissingLocation: shouldShowChildrenWhenMissingLocation = false,
  children,
  ...boxProps
}) => {
  const { sid: locationId } = useParams()
  const locationQuery = useLocation(locationId)
  const navigate = useNavigate()
  const routerLocation = useRouterLocation()

  // There's no reason to show the page header (or skeleton) if no title is provided
  const shouldShowPageHeader = title !== undefined

  // TODO: Decide what to do when navigating away from a page where locationId is key, like /alarm-sms-review/:alarmId
  // because the user intent is probably to disregard the current alarmId when searching for a new locationId.
  /**
   * Inserts or replaces the location ID provided in the current URL path and navigates to the resulting URL.
   *
   * @param locationId The location ID to use in the target URL.
   */
  const handleLocationSearch = (locationId: string) => {
    const currentUrlPath = routerLocation.pathname
    const urlWithLocationIdRegex = /^\/location(\/[0-9]+)?(.*)/
    const newUrlPath = currentUrlPath.replace(urlWithLocationIdRegex, `/location/${locationId}$2`)
    navigate(newUrlPath)
  }

  /* Page elements */

  const header = <Header headerText={title} />
  const headerSkeleton = (
    <Skeleton isLoading={true} variant="rounded" sx={{ blockSize: 'var(--size-display)', inlineSize: '30rem', marginBlock: 'var(--spacing-xxs)' }} />
  )
  const locationNotFoundErrorScreen = (
    <ErrorScreen
      data-testid="error-screen"
      title="Oops, sorry this SID or System Number could not be found!"
      message="A refresh may fix this issue."
    />
  )
  const locationSearch = <LocationSearch onSidChanged={handleLocationSearch} />

  /* Page layout configurations according to the location ID and loading state */

  let pageHeaderElement: ReactNode
  let pageBodyElement: ReactNode
  let locationSearchElement: ReactNode

  if (locationId === undefined) {
    pageHeaderElement = shouldShowPageHeader ? header : null
    pageBodyElement = shouldShowChildrenWhenMissingLocation ? children : null
    locationSearchElement = shouldShowLocationSearchBar ? locationSearch : null
  } else if (locationQuery.isLoading) {
    pageHeaderElement = shouldShowPageHeader ? headerSkeleton : null
    pageBodyElement = null
    locationSearchElement = null
  } else if (locationQuery.error?.status === 404) {
    pageHeaderElement = shouldShowPageHeader ? header : null
    pageBodyElement = locationNotFoundErrorScreen
    locationSearchElement = shouldShowLocationSearchBar ? locationSearch : null
  } else {
    pageHeaderElement = shouldShowPageHeader ? header : null
    pageBodyElement = children
    locationSearchElement = shouldShowLocationSearchBar ? locationSearch : null
  }

  return (
    <Stack
      sx={{
        minBlockSize: '100vh',
        inlineSize: '100%',
        padding: 'var(--spacing-m) var(--spacing-l)',
        gap: 'var(--spacing-l)'
      }}
    >
      <Box sx={{ minBlockSize: '2.75rem' }}>{pageHeaderElement}</Box>

      <Stack sx={{ flexGrow: 1, overflowX: 'auto', rowGap: 'var(--spacing-xxl)' }}>
        {locationSearchElement}
        <Box {...boxProps}>{pageBodyElement}</Box>
      </Stack>
    </Stack>
  )
}
