import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ErrorScreen } from '../../components/ErrorScreen'
import { Page } from '../../components/Page'
import { Typography } from '@mui/material'

export const UnauthorizedPage: FC = () => {
  return (
  <Page showLocationSearchBar={false} showChildrenWhenMissingLocation={true}>
      <ErrorScreen
        title="Unauthorized"
        message={
          <>
            <Typography>You are not authorized to view this page</Typography>
            <Typography>
              You can return to the <Link to="/">home page</Link>
            </Typography>
          </>
        }
      />
    </Page>
  )
}
