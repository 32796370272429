import { FC } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useLocation as useLocationData } from '../../api/apiQueries.ts'
import { NavTabs } from '../../components/NavTabs'
import { Page } from '../../components/Page'
import { BannerError } from './BannerError.tsx'

export const PermitPage: FC = () => {
  const { sid: locationId } = useParams()
  const locationQuery = useLocationData(locationId)

  const locationData = locationQuery.data
  const tabs = [
    { label: 'Permits', to: `/location/${locationId}/permits/overview`, dataTestId: 'overview' },
    { label: 'Jurisdiction Details', to: `/location/${locationId}/permits/jurisdiction-details`, dataTestId: 'jurisdiction-details-tab' }
  ]

  return (
    <Page title="Permit Management">
      {locationData && (
        <>
          <BannerError location={locationData} />
          <NavTabs tabs={tabs} />

          <Outlet />
        </>
      )}
    </Page>
  )
}
