import { Box, Button, Divider, MenuItem, Stack, StackProps, TextField, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  AddressCandidate,
  AddressValidation,
  AddressValidationCorrection,
  AddressValidationIssue,
  CreateLocationChange,
  LocationChangeAddress,
  LocationChangePropertyType
} from '../../../api';
import { useValidateNewFormAddressFn } from '../../../api/apiQueries';
import { addressValidationResultsAtom, currentLocationChangeAtom, selectedAddressValidationResultAtom } from '../../../atoms';
import { RESIDENCE_TYPE_ARRAY } from '../../../constants';
import {
  IssueCorrectionMessage,
  mapFormDataToLocationModel,
  mapIssueTypeToUserFriendlyMessage,
  mapLocationChangeAddressToRealtorAddress,
  mapRealtorAddressToLocationChangeAddress
} from './addressScheduleFormUtils';

interface AddressSchedulerFormProps extends StackProps {
  sid: string
}

export const AddressSchedulerForm: FC<AddressSchedulerFormProps> = ({ sid }) => {
  const navigate = useNavigate()

  const [currentLocationAtom, setCurrentLocationAtom] = useAtom(currentLocationChangeAtom)
  const [addressValidationResults, setAddressValidationResults] = useAtom(addressValidationResultsAtom)
  const [selectedAddressValidationResult, setSelectedAddressValidationResultAtom] = useAtom(selectedAddressValidationResultAtom)
  const [addressIssues, setAddressIssues] = useState<IssueCorrectionMessage[]>([])
  const [errorFields, setErrorFields] = useState<string[]>([])

  const validateNewFormAddress = useValidateNewFormAddressFn()

  const onSubmit = async (formData: Partial<LocationChangeAddress>) => {
    // Map form data to LocationChange type
    const locationData: CreateLocationChange = mapFormDataToLocationModel(formData)
    const mappedAddressForValidationInRealtor: AddressValidation = mapLocationChangeAddressToRealtorAddress(locationData.address)
    const validatedAddressResults = await validateNewFormAddress(sid, mappedAddressForValidationInRealtor)

    setCurrentLocationAtom(locationData)
    setSelectedAddressValidationResultAtom(undefined)
    setAddressValidationResults(validatedAddressResults)
    // Navigate to next page based on validation results.
    const nextPage = validatedAddressResults.candidates.some(candidate => candidate.corrections.length > 0 || candidate.issues.length > 0)
      ? 'validation-results'
      : 'date-picker'
    navigate(`../${nextPage}`)
  }

  const handleCancel = () => {
    navigate(`../overview`)
  }

  const [initialFormState, setInitialFormState] = useState<Partial<LocationChangeAddress>>(currentLocationAtom?.address ?? {})

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    trigger,
    setValue,
    watch,
    getValues
  } = useForm<Partial<LocationChangeAddress>>({ defaultValues: initialFormState })

  useEffect(() => {
    // If returning from validation-results - autopopulate with selection.
    // also need to verify if selectedAddressValidationResult its a number
    if (selectedAddressValidationResult && typeof selectedAddressValidationResult === 'number' && addressValidationResults) {
      // Select the proper candidate.
      const selectedValidationResultCandidate: AddressCandidate = addressValidationResults.candidates[selectedAddressValidationResult]
      const validationIssues: IssueCorrectionMessage[] = []
      const fieldsWithErrors: string[] = []

      // Get all issues
      selectedValidationResultCandidate.issues.forEach((issue: AddressValidationIssue) => {
        const issueInformation = mapIssueTypeToUserFriendlyMessage(issue)
        if (!validationIssues.includes(issueInformation)) {
          validationIssues.push(issueInformation)
        }
      })
      // Get all corrections
      selectedValidationResultCandidate.corrections.forEach((issue: AddressValidationCorrection) => {
        const issueInformation = mapIssueTypeToUserFriendlyMessage(issue)
        if (!validationIssues.includes(issueInformation)) {
          validationIssues.push(issueInformation)
        }
      })
      // Get distinct list of fields with errors
      validationIssues.forEach((issue: IssueCorrectionMessage) => {
        if (!fieldsWithErrors.includes(issue.field)) fieldsWithErrors.push(issue.field)
      })

      setAddressIssues(validationIssues)
      setErrorFields(fieldsWithErrors)
      setInitialFormState(mapRealtorAddressToLocationChangeAddress(selectedValidationResultCandidate))
    } else if (currentLocationAtom) {
      setInitialFormState(currentLocationAtom)
    }
    // TODO: ADD CASE FOR PULLING IN THE EDIT. MAKE IT SO THAT ON THAT TICKET, THEY HAVE TO POPULATE THE currentLocationAtom
    // BEFORE NAVIGATING OVER TO THE FORM.
  }, [])

  const formatErrorMessage = (error: IssueCorrectionMessage): string => {
    if (error.field === 'Unknown') {
      return error.message
    }
    return `We couldn't verify the ${error.errorText}. Please review it before continuing.`
  }

  const clearErrorForField = (id: string) => {
    const errorFieldList = [...errorFields]
    const filteredList = errorFieldList.filter((item: string) => item !== id)
    setErrorFields(filteredList)
  }

  return (
    <Stack spacing={'var(--spacing-s)'} sx={{ width: '100%' }}>
      <Box
        sx={{
          bgcolor: 'var(--bg-surface)',
          borderRadius: 'var(--radius-m)',
          p: 'var(--spacing-m)',
          gap: 'var(--spacing-xl)'
        }}
      >
        <Typography variant="h2" fontSize={'var(--size-h1)'} fontWeight="500" color="text.primary">
          Schedule an Address Change
        </Typography>
        <Stack sx={{ marginTop: 'var(--spacing-xl)', marginLeft: 'var(--spacing-m)' }} spacing={'var(--spacing-m)'}>
          {addressIssues.length === 0 && (
            <Typography variant="h3" fontSize={'var(--size-h2)'} fontWeight="500" color="text.primary">
              Enter the New Address
            </Typography>
          )}
          {addressIssues.length !== 0 && (
            <>
              <Typography variant="h3" fontSize={'var(--size-h2)'} fontWeight="500" color="text.primary">
                Update The Address Below
              </Typography>
              {addressIssues.map((error: IssueCorrectionMessage) => {
                return (
                  <Typography key={error.field} variant="body1" fontSize={'var(--size-l)'} fontWeight="300" color="text.primary">
                    {formatErrorMessage(error)}
                  </Typography>
                )
              })}
            </>
          )}
          <form onSubmit={handleSubmit(onSubmit)} data-testid={'address-change-form'}>
            <Stack spacing={'var(--spacing-l)'}>
              <TextField
                {...register('propertyType', {
                  required: 'Property Type is required'
                })}
                slotProps={{
                  // @ts-expect-error - select doesn't expose the data attributes but this is valid
                  select: { ['data-testid']: 'propertyType' }
                }}
                select
                value={watch('propertyType') || ''}
                label="Property Type"
                error={!!errors.propertyType || errorFields.includes('propertyType')}
                helperText={errors.propertyType?.message}
                fullWidth
                onFocus={() => clearErrorForField('propertyType')}
                onChange={e => {
                  setValue('propertyType', e.target.value as LocationChangePropertyType)
                  const values = getValues()
                  if (values.street1 && values.postalCode && values.state && values.city) trigger()
                }}
              >
                {RESIDENCE_TYPE_ARRAY.map(type => {
                  return (
                    <MenuItem key={type} data-testid="propertyType_value" value={type}>
                      {type}
                    </MenuItem>
                  )
                })}
              </TextField>
              <TextField
                {...register('street1', {
                  required: 'Address is required'
                })}
                slotProps={{
                  htmlInput: { ['data-testid']: 'street1' }
                }}
                label="Address"
                error={!!errors.street1 || errorFields.includes('street1')}
                helperText={errors.street1?.message}
                fullWidth
                onFocus={() => {
                  // clearErrorForField('street1')
                }}
              />
              <TextField
                {...register('street2', {})}
                slotProps={{
                  htmlInput: { ['data-testid']: 'street2' }
                }}
                label="Apartment/Suite/Floor"
                fullWidth
                error={errorFields.includes('street2')}
                onFocus={() => {
                  clearErrorForField('street2')
                }}
              />
              <Stack direction="row" spacing={'var(--spacing-s)'}>
                <TextField
                  {...register('city', {
                    required: 'City is required'
                  })}
                  slotProps={{
                    htmlInput: { ['data-testid']: 'city' }
                  }}
                  label="City"
                  error={!!errors.city || errorFields.includes('city')}
                  helperText={errors.city?.message}
                  fullWidth
                  onFocus={() => {
                    clearErrorForField('city')
                  }}
                />
                <TextField
                  {...register('state', {
                    required: 'State is required'
                  })}
                  slotProps={{
                    htmlInput: { ['data-testid']: 'state' }
                  }}
                  label="State"
                  error={!!errors.state || errorFields.includes('state')}
                  helperText={errors.state?.message}
                  fullWidth
                  onFocus={() => {
                    clearErrorForField('state')
                  }}
                />
                <TextField
                  {...register('postalCode', {
                    required: 'Zip Code is required',
                    pattern: {
                      value: /^\d{5}(-\d{4})?$/,
                      message: 'Enter a valid 5 or 9-digit ZIP code (e.g., 12345 or 12345-6789)'
                    }
                  })}
                  slotProps={{
                    htmlInput: { ['data-testid']: 'postalCode' }
                  }}
                  label="Zip Code"
                  type="text"
                  error={!!errors.postalCode || errorFields.includes('postalCode')}
                  helperText={errors.postalCode?.message}
                  fullWidth
                  onFocus={() => {
                    clearErrorForField('postalCode')
                  }}
                />
              </Stack>
              <Divider sx={{ borderColor: 'var(--bg-surface-medium)' }} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 'var(--spacing-s)' }}>
                <Button variant="outlined" onClick={handleCancel} data-testid={'cancel-address-change-button'}>
                  Cancel
                </Button>
                <Button disabled={!isValid} variant="contained" color="primary" type="submit" data-testid={'first-next-address-change-button'}>
                  Continue
                </Button>
              </Box>
            </Stack>
          </form>
        </Stack>
      </Box>
    </Stack>
  )
}
