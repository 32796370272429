import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Box } from '@mui/material'
import { FC } from 'react'

interface AddressConfirmationDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  customerAddress: {
    street1?: string
    city?: string
    zip?: string
  } | undefined
}

export const AddressConfirmationDialog: FC<AddressConfirmationDialogProps> = ({ isOpen, onClose, onConfirm, customerAddress }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Is the Customer Address Correct?</DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: 'var(--size-m)',
            fontWeight: '300',
            lineHeight: 'var(--lineheight-m, 1.125rem)',
            letterSpacing: 'var(--tracking-default, 0rem)',
            color: 'var(--text-primary)'
          }}
        >
          Please double check the customer address as this will affect dispatch in the event of an emergency
        </Typography>
        <Box sx={{ marginTop: 'var(--spacing-m)' }}>
          <Typography fontSize="var(--size-l)" fontWeight="700" color="text.primary">
            Customer Address
          </Typography>
          <Typography fontSize="var(--size-l)" fontWeight="400" color="text.primary" data-testid="address-confirmation-address-dialog-street">
            {customerAddress?.street1}
          </Typography>
          <Typography fontSize="var(--size-l)" fontWeight="400" color="text.primary" data-testid="address-confirmation-address-dialog-city-zip">
            {customerAddress?.city} {customerAddress?.zip}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="outlined" onClick={onClose} data-testid="back-to-edit">
          Back to Edit
        </Button>
        <Button variant="outlined" onClick={onConfirm} data-testid="confirm-address">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
