import { Box, Typography, useTheme } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import { FC, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { AuthCheck } from '../../AuthCheck'

interface NavItemProps {
  isOpen: boolean
  scopes: string[]
  to: string
  label: string
  icon: ReactNode
  testId: string
}

export const NavItem: FC<NavItemProps> = ({ isOpen, scopes, to, label, icon, testId }) => {
  const theme = useTheme()

  return (
    // TODO: For permits only, check for read only. No need to check for write for access.
    <AuthCheck has={scopes}>
      <Box
        sx={{
          display: 'contents',
          '.nav-link': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 'var(--base-values-2x)',
            maxBlockSize: 'var(--base-values-8x)',
            paddingBlockStart: 'var(--base-values-2x)',
            paddingBlockEnd: 'var(--base-values-2x)',
            paddingInline: isOpen ? 'var(--spacing-s)' : 'var(--spacing-l)',
            backgroundColor: 'transparent',
            fontSize: 'var(--size-l)',
            textDecorationLine: 'none',
            whiteSpace: 'nowrap',
            transition: theme.transitions.create('padding', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            })
          },
          '.nav-link__active': {
            backgroundColor: 'var(--bg-highlight)'
          },
          '.nav-link:not(.nav-link__active):hover': {
            backgroundColor: theme.palette.action.hover
          }
        }}
      >
        <NavLink
          to={to}
          title={isOpen ? undefined : label}
          data-testid={testId}
          // Using classes to allow styling hover states
          className={({ isActive }) => (isActive ? 'nav-link nav-link__active' : 'nav-link')}
        >
          <ListItemIcon sx={{ minWidth: 'auto', color: 'var(--shape-default)' }}>{icon}</ListItemIcon>
          <Typography
            component="span"
            style={{
              color: 'var(--text-primary)',
              display: isOpen ? 'block' : 'none',
              opacity: isOpen ? 1 : 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              transition: `
                display ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp} allow-discrete,
                opacity ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}
              `
            }}
          >
            {label}
          </Typography>
        </NavLink>
      </Box>
    </AuthCheck>
  )
}
