import { FC, PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { Outlet } from 'react-router-dom'
import SideNav from '../../components/SideNav/SideNav'

export const AppLayout: FC<PropsWithChildren> = () => {
  return (
    <ErrorBoundary>
      <Box sx={{ display: 'flex' }}>
        <SideNav />
        <Box sx={{ backgroundColor: 'var(--bg-surface-low)', flexGrow: 1 }}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
